<template>
  <div class="main">
    <Hero />
  </div>
</template>

<script>
import Hero from './sections/Hero.vue'
export default {
  components: {
    Hero
  }
}
</script>

<style scoped>
/* .main {
  width:100%;
  height: 100%;
} */
</style>