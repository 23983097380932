  <template>
  <div class="boma-hero overlay">
   <div class="hero-inner">
    <div class="top-with-navigation">
       <div class="logo">
        <div class="text">
          <p class="mb-4">
            Under the <br class="_rm"/>
            aegis of
          </p>
        </div>
        <div class="image">
          <img src="../../../assets/boma/auweblogo-en.svg" alt="">
        </div>
       </div>
       <!-- navigation content -->
        <!-- <div id="myNav"  class="overlay-content">
            <button @click="closeNav" class="closebtn">&times;</button>
            <div class="navbar">
              <a href="#">Home</a>
              <a href="#">About</a>
              <a href="/Boma2022/agenda">Agenda</a>
              <a href="/Boma2022/contact">Contact</a>
              <a href="#">Register</a>
            </div>
        </div> -->
        <!-- toggle button -->
        <!-- <button id="nav_toggler" @click="openModal">
          <i class="fas fa-bars"></i>
        </button> -->
    </div>

    <div class="hero-text">
      <div class="row">
        <div class="col-md-6">
          <div class="image_">
            <img class="img-fluid" src="../../../assets/boma/boma logo.svg" alt="">
          </div>
        </div>
        <div class="col-md-5">
          <div class="header">
            <h2>
              Taking Stock of the
              <br class="_rm"/> "African Century"
            </h2>
          </div>

          <div class="date">
            <p>
              Friday 8th July, 2022 <br/>
              10am GMT (12pm CET)
            </p>
          </div>

          <div class="button">
            
              <a href="https://flash-forward-co-za.zoom.us/webinar/register/WN_ghzd5kSsRaGl1xWfh-P53Q"
              target="_blank">
                <button>
                  Register
                </button>
              </a>

              <a href="https://www.africaboma.com/boma2022"
              target="_blank">
                <button class="program">
                  Program/Agenda
                </button>
              </a>
            
          </div>

        </div>
      </div>
   </div>

   </div>
  </div>
</template>

<script>
export default {
  methods: {
    openModal() {
      document.getElementById("myNav").style.display = "block";
    },
    closeNav(){
      console.log('here i am')
      document.getElementById("myNav").style.display = "none";
    }
  }
}
</script>

<style lang="scss" scoped>
  
  .boma-hero{
    width: 100vw;
    height: 100vh;
    background-image: url('../../../assets/boma/pattern.svg'), linear-gradient(rgba(0, 138, 68, .8),rgba(0, 138, 68, .8));
    background-blend-mode: overlay;
  }
  .hero-inner{
    padding-right: 10%;
    padding-left: 10%;
    height: 100vh;
    .top-with-navigation{
      display: flex;
      justify-content: space-between;
      .closebtn{
        display: none;
      }
     .logo{
        width: 114px;
        height: 168px;
        border-bottom-right-radius: 70px;
        border-bottom-left-radius: 70px;
        padding-top: 38px;
        padding-left: 24px;
        padding-right: 24px;
        background-color: #fff;
      .text{
        p{
          margin: 0px;
          padding: 0px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          text-align: center;
        }
      }
     }
    //  TOP NAVIGATION
     .overlay-content{
      .navbar{
        padding-top: 100px;
        display: block;
        a{
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
          &:not(:last-of-type) {
            margin-right: 22px;
          }
        }
      }
     }
     // NAV TOGGLER
     #nav_toggler{
      display: none;
     }
    }
    .hero-text{
      margin-top: 100px;
      .header {
        margin-bottom: 50px;
        h2{
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 1000;
          font-size: 64px;
          line-height: 78px;
          color: #FFFFFF;
        }
      }
      .date{
        p{
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 37px;
          color: #FFFFFF;
          padding: 0px;
          margin: 0px;
        }
      }

      .button{
        margin-top: 70px;
        button{
          background: #83BC3F;
          outline:none;
          border:none;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 40px;
          text-align: center;
          color: #FFFFFF;
          text-align: center;
          width: 340px;
          height: 60px;
        }
        .program{
          background: #FFFFFF;
          color:#83BC3F;
          margin-top: 1em;
        }
      }
    }
  }
  // responsiveness
  @media screen and (max-width: 768px) {
    ._rm{
      display: none;
    }
    .hero-inner{
      padding-right: 10%;
      padding-left: 10%;
      height: 100%;
    .top-with-navigation{
      .logo{
        background-color: #fff;
        width: 80px;
        height: 120px;
        border-bottom-right-radius: 70px;
        border-bottom-left-radius: 70px;
        padding: 10px;
        padding-top: 20px;
      }
      #nav_toggler{
        display: block;
        width: 50px;
        height: 50px;
        margin-top: 50px;
        background-color: transparent;
        color: #fff;
        font-size: 2.2em;
        outline: none;
        border: none;
      }
      .closebtn {
        display: block;
        font-size: 60px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        outline: none;
        border:none;
        color: #818181;
        float:right;
        &:hover,&:focus {
            color: #f1f1f1;
          }
      }
      // navigation content
      //  TOP NAVIGATION
    .overlay-content {
      height: 100%;
      width: 100%;
      position: fixed;
      display: none;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0, 0.9);
      overflow-x: hidden;
      transition: 0.5s;
      .navbar {
          width: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 0px;
          height: calc(100% - 50px);
        a {
          padding: 8px;
          text-decoration: none;
          font-size: 36px;
          color: #818181;
          display: block;
          transition: 0.3s;
          text-align: center;
          
          &:hover,&:focus {
            color: #f1f1f1;
          }
          &:not(:last-of-type) {
            margin-right: 0px;
            margin-bottom: 25px;
          }
        }
      }
    }
    }
    .hero-text{
      margin-top: 50px;
      .header {
        margin-bottom: 30px;
        margin-top: 30px;
        h2{
          font-family: 'Montserrat';
          font-weight: 800;
          font-size: 37px;
          text-align: center;
          line-height: 1;
        }
      }
      .date{
        p{
          font-size: 20px;
          text-align: center;
        }
      }
      .button{
        margin-top: 50px;
        button{
          width: 100%;
        }
      }
    }
  }
  }

  // smaller screens
  @media screen and (max-height: 450px) {
  .navbar a {font-size: 20px}
   .top-with-navigatio .closebtn {
    font-size: 40px;
    }
}
</style>